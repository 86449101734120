import { OverViewAgents, OverViewChart, OverViewTraffic } from "components"
import { useReports } from "hooks"


export const Reports = () => {
  const { data: reports, isLoading } = useReports()


  return (
    <div className='w-full flex flex-col    '>
      <OverViewChart
        client_count={reports?.data?.data?.['client_count']}
        message_count={reports?.data?.data?.['message_count']}
      />
      <OverViewTraffic grath={reports?.data?.data?.['grath']} />
      {/* <OverViewAgents /> */}
    </div >
  )
}