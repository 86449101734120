import { ModalAddTeams, ModalDeleteTeams } from "components/teams-components"
import Table from "components/widget/CTable/Table"
import { useTeams , useActiveTeams } from "hooks"
import { CSvgTrash } from "icon"
import { useState } from "react"
import { methodHandelActiveTeams } from "utils"

export const Teams = () => {
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false)
  const [visibleAdd, setVisibleAdd] = useState<boolean>(false)
  const [selectItem, setSelectItem] = useState<any>(null)
  const { data: teams, isLoading } = useTeams(true)
  const { mutate } = useActiveTeams()

  const col = [
    {
      title: "Name ",
      render: (i: any) => (<>{i?.name}</>)
    },
    {
      title: "Email ",
      render: (i: any) => (<>{i?.operator_email}</>)
    },
    {
      title: "Change Status",
      render: (i: any) => (
        <>
          {i.status != 0 ?
            <label key={i.id} className="table-toggle">
              <>
                <input type="checkbox" onChange={()=>handelChangeStatus(i)} checked={i.active} />
                <div className="table-toggle-main" />
              </>
            </label>
            :
            <span></span>
          }
        </>
      )
    },
    {
      title: "Delete",
      render: (i: any) => (
        <>
          <button onClick={() => handelStartDelete(i)} className="w-[35px] h-[35px] rounded border border-[tomato] text-[tomato] flex items-center justify-center">
            <CSvgTrash className="w-[20px] fill-[tomato]" />
          </button>
        </>
      )
    },
  ]

  const handelChangeStatus=(item:any)=>{
    methodHandelActiveTeams(item , mutate)
  }

  const handelStartDelete = (item: any) => {
    setSelectItem(item)
    setVisibleDelete(true)
  }

  return (
    <>
      <div className="w-full flex flex-col md:p-[20px] p-[10px] bg-hoory-100  rounded-lg">
        <div className="w-full   flex items-center justify-end mb-4">
          <button onClick={() => { setVisibleAdd(true) }} className="px-3 h-[35px] text-xs rounded-md  bg-hoory-175 text-hoory-200"> New +</button>
        </div>
        <Table columns={col} data={teams?.data?.data} />
      </div>

      <ModalDeleteTeams
        visible={visibleDelete}
        setVisible={setVisibleDelete}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
      />

      <ModalAddTeams
        visible={visibleAdd}
        setVisible={setVisibleAdd}
      />

    </>
  )
}