export const methodHandelLogin = (
    refForm:any ,
    mutate:(obj:any)=>void ,
) => {
    let postData = {
        'email':refForm.current['email'].value ,
        'password':refForm.current['password'].value ,
    }
    mutate(postData)
}



export const methodHandelRegister = (
    refForm:any ,
    mutate:(obj:any)=>void ,
) => {
    let postData = {
        'email':refForm.current['email'].value ,
        'password':refForm.current['password'].value ,
        'password2':refForm.current['password'].value ,
        'name':refForm.current['name'].value ,
        'family':refForm.current['family'].value ,
    }
    mutate(postData)
}



export const methodHandelSendMail = (
    refForm:any ,
    mutate:(obj:any)=>void ,
) => {
    let postData = {
        'emails':[refForm.current['email'].value] ,
    }
    mutate(postData)
}


export const methodHandelChangePassword = (
    refForm:any ,
    mutate:(obj:any)=>void ,
) => {
    let postData = {
        'new_password':refForm.current['password'].value ,
        'code':refForm.current['code'].value ,
    }
    mutate(postData)
}

