import {  NotificationApi , AddNotificationApi ,InboxApi, EditNotificationApi ,AcceptConfirmApi, DeleteNotificationApi , ChangeStatusNotificationApi} from "api";
import {useQueryClient,   useQuery , useMutation } from "react-query"
import { toast } from "react-toastify";

export const useNotification = () => {
  return useQuery("notification", NotificationApi, {
    refetchOnWindowFocus: false,
  })
}

export const useInbox = () => {
  return useQuery("inbox", InboxApi, {
    refetchOnWindowFocus: false,
  })
}


export const useDeleteNotification = (
  id: any,
  setVisible: (bol: boolean) => void ,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => DeleteNotificationApi(id),
    onSuccess: res => {
      if (res.data.status) {
        toast(res.data.data, { type: "success" })
        setVisible(false)
        queryClient.refetchQueries('notification')
      }
      else {
        toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}



export const useAddNotification = (
  setVisible: (bol: boolean) => void ,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (postData) => AddNotificationApi(postData),
    onSuccess: res => {
      if (res.data.status) {
        toast(res.data.message, { type: "success" })
        setVisible(false)
        queryClient.refetchQueries('notification')
      }
      else {
        toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}




export const useEditNotification = (
  id: any,
  setVisible: (bol: boolean) => void ,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (postData) => EditNotificationApi(postData, id),
    onSuccess: res => {
      if (res.data.status) {
        toast(res.data.message, { type: "success" })
        setVisible(false)
        queryClient.refetchQueries('notification')
      }
      else {
        toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}



export const useChangeStatusNotification = (
  id: any,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (postData) => ChangeStatusNotificationApi(postData, id),
    onSuccess: res => {
      if (res.data.status) {
        toast(res.data.message, { type: "success" })
        queryClient.refetchQueries('notification')
      }
      else {
        toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}



export const useConfirmInbox = (
  id: any,
  setVisible: (bol: boolean) => void ,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (postData) => AcceptConfirmApi(postData ,id),
    onSuccess: res => {
      if (res.data.status) {
        toast(res.data.data, { type: "success" })
        setVisible(false)
        queryClient.refetchQueries('inbox')
      }
      else {
        toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}

