import { ConfigStoreTypes } from 'types';
import create from 'zustand';



interface ILayout {
    typeAuthPage: string,
    setTypeAuthPage: (data: string) => void,
    userInfo: any,
    setUserInfo: (data: any) => void,
    config: ConfigStoreTypes,
    setConfig: (data: ConfigStoreTypes) => void,
}

// zustand state

export const LayoutStore = create<ILayout>((set) => ({
    // تغییر کردن لایگن به رجیستر
    typeAuthPage: 'login',
    setTypeAuthPage: (data: string) => set((state: any) => ({ typeAuthPage: data })),
    // اطلاعات کاربر
    userInfo: undefined,
    setUserInfo: (data: any) => set((state: any) => ({ userInfo: data })),
    // تغییر وضعیت ربات به افلاین , انلاین و یا بیزی
    config: {
        'status': '1',
        'mode': 'light',
        'color': 'string'
    },
    setConfig: (data: any) => set(() => ({ config: data })),
}))
