import { CSvgAgents, CSvgSearch } from "icon"
import { ConvertionStore } from "store"

export const ConvertionChatList = ({ listConvertion, setActiveChat, activeChat , setBotOff }: any) => {
  const { setConvertion, setConvertionPagination } = ConvertionStore()

  const handelSelectConvertion = async (item: any) => {
    if (activeChat?.chat_room_id != item.chat_room_id) {
      setBotOff(false)
      await setConvertion([])
      await setConvertionPagination({
        'total': 1,
        'current': 1,
      })
      setActiveChat(item)
    }
  }

  return (
    <div className="w-full md:h-full h-fit bg-hoory-100 rounded-lg flex flex-col md:p-[20px] p-[10px]">
      {/* <div className="w-full h-[40px] border border-hoory-75 rounded-md flex items-center px-2">
        <CSvgSearch className="w-[25px] stroke-gray-400" />
        <input placeholder="Search Convertion" className="w-[calc(100%-25px)] h-full text-sm border-none px-1 focus:outline-none bg-transparent" />
      </div> */}
      <div className="w-full h-fit md:max-h-screen max-h-[400px]  flex flex-col overflow-y-auto mt-2">
        {listConvertion?.map((item: any, index: number) =>
          <button onClick={() => handelSelectConvertion(item)} key={index} className={`w-full p-2 flex items-center rounded-md ${activeChat?.chat_room_id == item.chat_room_id ? 'bg-hoory-175' : 'bg-transparent'} `}>
            <div className="w-[50px] h-[50px] bg-hoory-175 flex items-center justify-center text-hoory-200 rounded-full shadow">
              <CSvgAgents className="fill-hoory-200 w-[25px]" />
            </div>
            <div className="w-[calc(100%-60px)] ml-2 flex flex-col items-start">
              <span className="text-sm">{item.chat_room_client_id}</span>
              <span className="text-gray-400 text-xs ">{item.chat_room_pixely_bot_name}</span>
            </div>
          </button>
        )}
      </div>
    </div>
  )
}