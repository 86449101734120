import { GetChatApi, SendMessageChatApi, SetOffApi, StartChatApi } from "api";
import { useMutation } from "react-query"
import { methodHandelScrollBoxToBottom } from "utils";


export const useStartMessage = (
  setStepChat: (str: string) => void,
) => {
  return useMutation({
    mutationFn: (postData) => StartChatApi(postData),
    onSuccess: res => {
      if (res.data.status) {
        localStorage.setItem('room_info', JSON.stringify(res.data.data))
        localStorage.setItem('stepChat', '3')
        setStepChat('3')
      }
      else {
        // toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}


export const useListMessage = (
  setStepChat: (str: string) => void,
  setClientChatPagination: (data: any) => void,
  setClientChat: (data: any) => void,
  clientChat: any,
  page: number,
) => {
  return useMutation({
    mutationFn: (postData) => GetChatApi(postData),
    onSuccess: res => {
      if (res.data.status) {
        setClientChat([...res?.data?.data?.message_list, ...clientChat,]);
        setClientChatPagination({
          'total': res?.data?.data?.total_page,
          'current': page,
        })
        if (page == 1) {
          setTimeout(() => {
            let chatBoxRef: any = document.getElementById('chat-box-external')
            chatBoxRef.scrollTo({
              top: chatBoxRef.scrollHeight - chatBoxRef.clientHeight,
              behavior: 'smooth'
            });
          }, 100);
          localStorage.setItem('stepChat', '3')
          setStepChat('3')
        }
      }
      else {
        // toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}




export const useSendMessage = (
  chat: any,
  setChat: (data: any) => void,
  formRef: any,
  type: 'client' | 'operator'
) => {
  return useMutation({
    mutationFn: (postData) => SendMessageChatApi(postData),
    onSuccess: res => {
      if (res.data.status) {
        let data = [...chat]

        if (type == 'client') {
          data.push({
            'type': 'client',
            'date': res?.data?.data?.client_date,
            'message': formRef?.current?.['message']?.value
          })
          data.push({
            'type': 'bot',
            'date': res?.data?.data?.bot_date,
            'message': res?.data?.data?.bot_message
          })
        }

        if (type == 'operator') {
          data.push({
            'type': 'operator',
            'date': res?.data?.data?.operator_date,
            'message': res?.data?.data?.operator_message
          })
        }

        setChat(data)
        methodHandelScrollBoxToBottom(type)

      }
      else {
        // toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}






export const useManualMessage = (
  setBotOff: (bol: boolean) => void,
) => {
  return useMutation({
    mutationFn: (postData) => SetOffApi(postData),
    onSuccess: res => {
      if (res.data.status) {
        setBotOff(true)
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}
