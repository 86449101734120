import { BtnLoader, ModalAddTodo, ModalDeleteTodo, ModalDetailTodo, ModalEditTodo, ModalTags, SkeletonTodo } from "components"
import { useAllTodo, useDeleteTag } from "hooks"
import { CSvgClose, CSvgEdit, CSvgToDo, CSvgTrash } from "icon"
import { useState } from "react"
import { useSearchParams } from "react-router-dom"

export const Todo = () => {
  const [searchParams] = useSearchParams()
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false)
  const [visibleAdd, setVisibleAdd] = useState<boolean>(false)
  const [visibleEdit, setVisibleEdit] = useState<boolean>(false)
  const [visibleDetail, setVisibleDetail] = useState<boolean>(false)
  const [visibleTags, setVisibleTags] = useState<boolean>(false)
  const [selectItem, setSelectItem] = useState<any>(null)
  const [tagId, setTagId] = useState<any>(null)
  const { isLoading: loading, data: todo } = useAllTodo(searchParams.get("id"))
  const { isLoading, mutate } = useDeleteTag(searchParams.get("id"))

  const handelDeleteTag = (tag_id: number, todo_id: number) => {
    let postDats: any = { 'tag_id': tag_id, 'todo_id': todo_id }
    mutate(postDats)
  }

  const handelOpen = async (item: any, type: "detail" | "edit" | "tag" | "delete") => {
    setSelectItem(item)
    switch (type) {
      case "detail":
        setVisibleDetail(true)
        break;
      case "edit":
        setVisibleEdit(true)
        break;
      case "tag":
        setVisibleTags(true)
        break;
      case "delete":
        setVisibleDelete(true)
        break;
    }
  }


  return (
    <>
      <div className='w-full flex flex-col justify-center items-center pb-[20px] bg-hoory-100  rounded-lg  '>
        <div className="w-full h-[60px] flex items-center justify-between px-10 border-b">
          <h1 className="font-bold text-xl">TODO</h1>
          <button onClick={() => { setVisibleAdd(true) }} className="px-3 h-[35px] text-xs rounded-md  bg-hoory-175 text-hoory-200"> New +</button>
        </div>
        <div className="w-full flex flex-wrap  border-hoory-75 p-4">
          {!loading && todo?.data.data.map((item: any, index: number) =>
            <div key={index} className="md:w-[33.33%] w-full  px-2 pb-2">
              <div className="w-full  shadow border border-hoory-75 mb-2 rounded flex flex-col">
                <div className="w-full flex items-center p-2 justify-between">
                  <span className="text-xs text-gray-400">{item.deadline.slice(0, 10)}</span>
                  <div className="flex items-center">
                    <button onClick={() => handelOpen(item, 'delete')} className="w-[30px] h-[30px] mr-2 border border-hoory-75 flex items-center justify-center rounded bg-hoory-75">
                      <CSvgTrash className="w-[18px] fill-hoory-25 " />
                    </button>
                    <button onClick={() => handelOpen(item, 'edit')} className="w-[30px] h-[30px] border border-hoory-75 flex items-center justify-center rounded bg-hoory-75">
                      <CSvgEdit className="w-[18px] fill-hoory-25 " />
                    </button>
                  </div>
                </div>
                <div className="flex items-center p-2">
                  <div className="md:w-[50px] w-[35px] md:h-[50px] h-[35px] flex items-center justify-center bg-hoory-175 rounded">
                    <CSvgToDo className="fill-hoory-200 w-[25px]" />
                  </div>
                  <div className="w-[calc(100%-50px)] flex flex-col ml-2">
                    <span className="">{item.title}</span>
                    <span className="text-sm text-gray-400">{item.description.slice(0, 30)} ...</span>
                  </div>
                </div>
                <div className="w-full flex items-center flex-wrap px-2 pt-2">
                  {item?.tags?.map((tag: any, ind: number) =>
                    <>
                      {isLoading ?
                        <div key={ind} className="w-[100px] h-[28px] px-2 mx-2 mb-2 rounded bg-hoory-75 text-hoory-25 text-xs flex items-center justify-center relative">
                          <BtnLoader />
                        </div>
                        :
                        <div key={ind} onClick={() => handelDeleteTag( tag.id ,item.id)} className="h-[28px] px-2 mx-2 mb-2 rounded bg-hoory-75 text-hoory-25 text-xs flex items-center justify-center relative">
                          <span>{tag?.name}</span>
                          <div className="w-[20px] h-[20px] cursor-pointer rounded-full ml-2 border flex items-center justify-center shadow">
                            <CSvgClose className="w-[10px] fill-hoory-25 " />
                          </div>
                        </div>
                      }
                    </>
                  )}
                </div>
                <div className="w-full px-2">
                  <div className="w-full flex items-center justify-between border-t border-hoory-75 py-2">
                    <button onClick={() => handelOpen(item, 'detail')} className="px-2 h-[30px] rounded border border-hoory-200 text-hoory-200 text-xs">Detail</button>
                    <button onClick={() => handelOpen(item, 'tag')} className="px-2 h-[30px] rounded border border-hoory-200 text-hoory-200 text-xs">New Tag</button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {loading &&
            <SkeletonTodo />
          }
        </div>
      </div >

      <ModalAddTodo
        visible={visibleAdd}
        setVisible={setVisibleAdd}
        check_id={searchParams.get("id")}
      />

      <ModalEditTodo
        visible={visibleEdit}
        setVisible={setVisibleEdit}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
        check_id={searchParams.get("id")}
      />

      <ModalDeleteTodo
        visible={visibleDelete}
        setVisible={setVisibleDelete}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
        check_id={searchParams.get("id")}
      />

      <ModalDetailTodo
        visible={visibleDetail}
        setVisible={setVisibleDetail}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
      />

      <ModalTags
        visible={visibleTags}
        setVisible={setVisibleTags}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
        check_id={searchParams.get("id")}
      />

    </>
  )
}