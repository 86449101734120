import { BtnLoader } from "components/widget"
import {CModal} from "components"
import { useEditTrain } from "hooks"
import { useEffect, useRef, useState } from "react"
import { ModalTrainTypes } from "types"
import { methodHandelEditTrains } from "utils"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const ModalEditTrain = ({ setVisible, visible, selectItem, setSelectItem, bot_id }: ModalTrainTypes) => {
    const formRef: any = useRef()
    const [editorText, setEditorValue] = useState('');

    const { isLoading, mutate } = useEditTrain(bot_id, setVisible, selectItem?.id)

    const handelEditBot = (e: any) => {
        e.preventDefault()
        methodHandelEditTrains(formRef,editorText, mutate)
    }

    useEffect(() => {
        if (selectItem != null) {
            formRef.current['name'].value = selectItem.name
            setEditorValue(selectItem?.description)
        }
    }, [selectItem])

    return (
        <>
            <CModal onScap={() => { setSelectItem(null) }} visible={visible} setVisible={setVisible} uId="edit-trains" title="Edit Trains">
                <div className="w-full flex flex-col p-3">
                    <form onSubmit={handelEditBot} ref={formRef} className="w-full md:pr-4 pr-0">
                        <div className="w-full flex flex-col">
                            <span className="text-sm mb-2  ml-2 font-bold ">Train Name</span>
                            <input name="name" type="text" placeholder="Enter your bot name " className="w-full h-[40px] rounded-md border border-hoory-75 bg-hoory-100 px-[10px] transition-all duration-500 focus:outline-none focus:border-pos-100 text-sm" required />
                        </div>
                        <div className="w-full flex flex-col mt-6">
                            <span className="text-sm mb-2  ml-2 font-bold ">Description</span>
                            <ReactQuill className="trains-editor" theme="snow" value={editorText} onChange={setEditorValue} />
                        </div>
                        {isLoading ?
                            <button type={'button'} className="w-full h-[40px] bg-hoory-200 text-white text-sm mt-5 rounded-md flex items-center justify-center relative"><BtnLoader /></button>
                            :
                            <button type="submit" className="w-full h-[40px] bg-hoory-200 text-white text-sm mt-5 rounded-md">Submit Edit</button>
                        }
                    </form>
                </div>
            </CModal>
        </>
    )
}