import create from 'zustand';

interface IChat {
    clientChat: any,
    setClientChat: (data: any) => void,
    clientChatPagination: {
        'total': number,
        'current': number,
    },
    setClientChatPagination: (data: any) => void,
}

// zustand state

export const ChatStore = create<IChat>((set) => ({
    // لیست چت های کاربر را نگه میدارد
    clientChat: [],
    setClientChat: (newChat) => set((state) => ({
        clientChat: typeof newChat === 'function' ? newChat(state.clientChat) : newChat
    })),
    // صفحه بندی چت کلاینت
    clientChatPagination: {
        'total': 1,
        'current': 1,
    },
    setClientChatPagination: (data: any) => set(() => ({ clientChatPagination: data })),
}))
