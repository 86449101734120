import { ModalAddCheckList, ModalDeleteCheckList, ModalEditCheckList } from "components"
import Table from "components/widget/CTable/Table"
import { useChangeStatusNotification, useCheckList } from "hooks"
import { CSvgCheckList, CSvgEdit, CSvgTrash } from "icon"
import { useState } from "react"
import { Link } from "react-router-dom"

export const CheckList = () => {
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false)
  const [visibleAdd, setVisibleAdd] = useState<boolean>(false)
  const [visibleEdit, setVisibleEdit] = useState<boolean>(false)
  const [selectItem, setSelectItem] = useState<any>(null)
  const { data: checklist, isLoading } = useCheckList()



  const col = [
    {
      title: "Title",
      render: (i: any) => (<>{i?.title}</>)
    },
    {
      title: "Description",
      render: (i: any) => (<>{i?.description}</>)
    },
    {
      title: "Deadline",
      render: (i: any) => (<>{i?.deadline?.slice(0, 10)}</>)
    },
    {
      title: "Todo",
      render: (i: any) => (
        <>
          <Link to={`/p/checklist/todo?id=${i?.id}`}>
            <button onClick={() => handelStartEdit(i)} className="w-[35px] h-[35px] rounded border border-blue-400 text-[#FFA500] flex items-center justify-center">
              <CSvgCheckList className="w-[20px] fill-blue-400" />
            </button>
          </Link>
        </>
      )
    },
    {
      title: "Edit",
      render: (i: any) => (
        <>
          <button onClick={() => handelStartEdit(i)} className="w-[35px] h-[35px] rounded border border-[#FFA500] text-[#FFA500] flex items-center justify-center">
            <CSvgEdit className="w-[20px] fill-[#FFA500]" />
          </button>
        </>
      )
    },
    {
      title: "Delete",
      render: (i: any) => (
        <>
          <button onClick={() => handelStartDelete(i)} className="w-[35px] h-[35px] rounded border border-[tomato] text-[tomato] flex items-center justify-center">
            <CSvgTrash className="w-[20px] fill-[tomato]" />
          </button>
        </>
      )
    },
  ]


  const handelStartDelete = (item: any) => {
    setSelectItem(item)
    setVisibleDelete(true)
  }

  const handelStartEdit = (item: any) => {
    setSelectItem(item)
    setVisibleEdit(true)
  }

  return (
    <>
      <div className="w-full flex flex-col md:p-[20px] p-[10px] bg-hoory-100  rounded-lg">
        <div className="w-full   flex items-center justify-end mb-4">
          <button onClick={() => { setVisibleAdd(true) }} className="px-3 h-[35px] text-xs rounded-md  bg-hoory-175 text-hoory-200"> New +</button>
        </div>
        <Table columns={col} data={checklist?.data?.data} />
      </div>

      <ModalDeleteCheckList
        visible={visibleDelete}
        setVisible={setVisibleDelete}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
      />

      <ModalEditCheckList
        visible={visibleEdit}
        setVisible={setVisibleEdit}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
      />

      <ModalAddCheckList
        visible={visibleAdd}
        setVisible={setVisibleAdd}
      />
    </>
  )
}