import { SkeletonBase } from "./SkeletonBase"

export const SkeletonTodo = () => {

    return (
        <>
            {new Array(3).fill("").map((item: string, index: number) =>
                <div key={index} className="md:w-[33.33%] w-full  px-2 pb-2">
                    <div className="w-full  shadow border border-hoory-75 mb-2 rounded flex flex-col">
                        <div className="w-full flex items-center p-2 justify-between">
                            <span className="w-[61px] h-[16px] text-xs text-gray-400"><SkeletonBase /></span>
                            <div className="flex items-center">
                                <button className="w-[30px] h-[30px] mr-2  border-hoory-75 flex items-center justify-center rounded bg-hoory-75">
                                    <SkeletonBase />
                                </button>
                                <button className="w-[30px] h-[30px]  border-hoory-75 flex items-center justify-center rounded bg-hoory-75">
                                    <SkeletonBase />
                                </button>
                            </div>
                        </div>
                        <div className="flex items-center p-2">
                            <div className="md:w-[50px] w-[35px] md:h-[50px] h-[35px] flex items-center justify-center bg-hoory-175 rounded">
                                <SkeletonBase />
                            </div>
                            <div className="w-[calc(100%-50px)] flex flex-col ml-2">
                                <div className="w-[100px] h-[24px] "><SkeletonBase /></div>
                                <div className="w-[60%] h-[15px] mt-2"><SkeletonBase /></div>
                            </div>
                        </div>
                        <div className="w-full flex items-center flex-wrap px-2 pt-2">
                            {[0, 0, 0, 0, 5].map((todo: any, ind: number) =>
                                <>

                                    <div className="w-[100px] h-[28px]  mx-2 mb-2 rounded bg-hoory-75 text-hoory-25 text-xs flex items-center justify-center relative">
                                        <SkeletonBase />
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="w-full px-2">
                            <div className="w-full flex items-center justify-between border-t border-hoory-75 py-2">
                                <div className="w-[100px] h-[30px] roundedflex items-center justify-center relative"><SkeletonBase /></div>
                                <div className="w-[100px] h-[30px] roundedflex items-center justify-center relative"><SkeletonBase /></div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}