

export const Settings = () => {




  return (
    <div className='w-full flex flex-col justify-center items-center pb-[20px] bg-hoory-100  rounded-lg '>
      <div className="w-full h-[60px] flex items-center px-10">
        <h1 className="font-bold text-xl">General Settings</h1>
      </div>
      <div className="w-full flex flex-wrap border-t border-hoory-75  p-4">
        <form className="md:w-6/12 w-full md:pr-4 pr-0">
          <div className="w-full flex flex-col">
            <span className="text-sm mb-2  ml-2 font-bold ">Account Name</span>
            <input type="text" placeholder="Account Name" className="w-full h-[40px] rounded-md border border-hoory-75 bg-transparent px-[10px] transition-all duration-500 focus:outline-none focus:border-pos-100 text-sm" />
          </div>
          <div className="w-full flex flex-col mt-6">
            <span className="text-sm mb-2  ml-2 font-bold ">Site Language</span>
            <select className="w-full h-[40px] rounded-md border border-hoory-75 bg-transparent p-[10px] transition-all duration-500 focus:outline-none focus:border-pos-100 text-sm">
              <option value='english'>English (en)</option>
            </select>
          </div>
          <div className="w-full flex flex-col mt-6">
            <span className="text-sm mb-2  ml-2  ">Convertion continuity with emails is enavle for your account</span>
            <input type="text"  className="w-full h-[40px] rounded-md border border-hoory-75 bg-transparent px-[10px] transition-all duration-500 focus:outline-none focus:border-pos-100 text-sm" />
          </div>
          <button className="w-full h-[40px] bg-hoory-200 text-white text-sm mt-10 rounded-md">Submit</button>
        </form>
        <div className="md:w-6/12 w-full md:pl-4 pl-0 md:mt-0 mt-10">
          <div className="w-full bg-hoory-175 rounded-md p-3 flex flex-col">
            <span className="my-2 font-bold text-[17px]">Account ID :</span>
            <span className="text-xs text-gray-600">This ID is required if you are bulding an API based integraton</span>
            <div className="w-full h-[40px] text-sm rounded-md border border-hoory-75 bg-hoory-125 mt-3 flex items-center justify-between px-2">
              <span>455</span>
              <button className="w-[50px] h-[25px] rounded-full bg-hoory-200 text-white text-xs">Copy</button>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}