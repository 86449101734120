import { toast } from 'react-toastify';
import io from 'socket.io-client';
// const socket = io(`ws://api.pixelyai.com/ws/chat/57628e56-8b24-4404-a73c-a4ad9408aec1/client/ehsan@gmail.com`); 

export const methodHandelStartMessage = (
    client_email: any,
    bot_token: any,
    mutate: (obj: any) => void,
) => {
    localStorage.setItem('client_email', client_email)
    localStorage.setItem('bot_token', bot_token)
    let postData = {
        'bot_id': bot_token,
        'email': client_email,
    }
    mutate(postData)
}




export const methodHandelListMessage = (
    room_id: any,
    page: number,
    mutate: (obj: any) => void,
) => {
    let postData = {
        'chat_room': room_id,
        'page': page,
    }
    mutate(postData)
}




export const methodHandelSendMessage = (
    room: any,
    mutate: (obj: any) => void,
    formRef: any,
    type: 'client' | 'operator'
) => {
    let message = formRef?.current?.['message']?.value
    let postData = {
        'chat_room': room?.chat_room_id,
        'message': message,
        'type': type
    }
    mutate(postData)
}


export const methodHandelSendMessageSocket = (
    socket: any,
    formRef: any,
    chat: any,
    setChat: (data: any) => void,
    type: 'client' | 'operator'
) => {
    let message = formRef?.current?.['message']?.value
    if (socket && socket.readyState === WebSocket.OPEN && message) {
        setChat((prevChat: any) => [
            ...prevChat,
            {
                'type': type,
                'date': new Date().toISOString(),
                'message': message
            }
        ]);
        formRef?.current?.reset();
        socket.send(message);
        methodHandelScrollBoxToBottom(type)
    }
    else {
        toast('connection error', { type: "error" })
        // createWebSocketConnection(chat, setChat, setSocket);
        // setTimeout(() => {
        //     methodHandelSendMessageSocket(socket, formRef, chat, setChat); // تلاش مجدد برای ارسال پیام پس از اتصال مجدد
        // }, 3000); 
    }
}



export const createWebSocketConnection = (
    chat: any,
    setChat: (data: any) => void,
    setSocket: (data: any) => void,
    type: 'client' | 'operator',
    activeChat?: any,
) => {
    let room: any;
    type == 'client' ? room = JSON.parse(String(localStorage.getItem('room_info'))) : room = activeChat;
    if (room) {
        const wsUrl = `wss://api.pixelyai.com/ws/chat/${room?.chat_room_id}/${type}/${type == 'client' ? room?.chat_room_client : room?.chat_room_client_id}/`;
        const newSocket = new WebSocket(wsUrl);

        newSocket.onopen = () => {
            setSocket(newSocket);
            console.log('Connected to server');
        };

        newSocket.onclose = () => {
            console.log('WebSocket disconnected, retrying...');
            setTimeout(() => {
                createWebSocketConnection(chat, setChat, setSocket, type)
            }, 3000); // تلاش مجدد اتصال پس از 3 ثانیه
        };

        newSocket.onmessage = (event) => {
            try {
                let newMessage = JSON.parse(event.data)
                if (newMessage?.session != type) {
                    setChat((prevChat: any) => [
                        ...prevChat,
                        {
                            'type': newMessage?.session,
                            'date': new Date().toISOString(),
                            'message': newMessage?.message
                        }
                    ]);
                    methodHandelScrollBoxToBottom(type)
                }
            } catch (e) {
                return;
            }
        };

        newSocket.onerror = (error) => {
            newSocket.close(); // بسته شدن دستی سوکت در صورت بروز خطا
        };

        
    }
}



export const closeWebSocketConnection = (
    socket: any,
    setSocket: (data: any) => void,
) => {
    const closeSocket = () => {
        if (socket && socket.readyState === WebSocket.OPEN) {
            socket.close();
            setSocket(null)
        }
    };
    window.addEventListener('beforeunload', closeSocket);
    return () => {
        window.removeEventListener('beforeunload', closeSocket);
        closeSocket();
    };
}



export const methodHandelSetOff = (
    room: any,
    mutate: (obj: any) => void,
) => {
    let postData = {
        'chat_room': room?.chat_room_id,
    }
    mutate(postData)
}



export const methodHandelScrollBoxToBottom = (
    type: 'client' | 'operator',
) => {
    setTimeout(() => {
        //@ts-ignore
        document?.getElementById?.('send-message-external-form')?.reset?.();
        //@ts-ignore
        document?.getElementById?.('send-message-convertion-form')?.reset?.();
        if (type == 'client') {
            let chatBoxRef: any = document.getElementById('chat-box-external')
            chatBoxRef.scrollTo({
                top: chatBoxRef.scrollHeight - chatBoxRef.clientHeight,
                behavior: 'smooth'
            });
        }
        else {
            let chatBoxRef: any = document.getElementById('convertion-panel-container')
            chatBoxRef.scrollTo({
                top: chatBoxRef.scrollHeight - chatBoxRef.clientHeight,
                behavior: 'smooth'
            });
        }
    }, 100);
}