import { BtnLoader } from "components";
import { useAddBot } from "hooks";
import { useRef } from "react";
import { methodHandelAddBots } from "utils";

export const AddBots = () => {
  const refForm: any = useRef()
  const { isLoading, isError, error, mutate } = useAddBot()


  const handelAddBot = (e: any) => {
    e.preventDefault();
    methodHandelAddBots(refForm, mutate)
  }


  return (
    <div className='w-full flex flex-col justify-center items-center pb-[20px] bg-hoory-100  rounded-lg  '>
      <div className="w-full h-[60px] flex items-center px-10 border-b">
        <h1 className="font-bold text-xl">Add AI</h1>
      </div>
      <div className="w-full flex flex-wrap  border-hoory-75 p-4">
        <form onSubmit={handelAddBot} ref={refForm} className="md:w-6/12 w-full md:pr-4 pr-0">
          <div className="w-full flex flex-col">
            <span className="text-sm mb-2  ml-2 font-bold ">AI Name</span>
            <input name="name" type="text" placeholder="Enter your AI name " className="w-full h-[40px] rounded-md border border-hoory-75 bg-transparent px-[10px] transition-all duration-500 focus:outline-none focus:border-pos-100 text-sm" required />
          </div>
          <div className="w-full flex flex-col mt-6">
            <span className="text-sm mb-2  ml-2 font-bold ">Description</span>
            <textarea name="description" placeholder="Enter your Description " className="w-full h-[100px] rounded-md border border-hoory-75 bg-transparent p-[10px] transition-all duration-500 focus:outline-none focus:border-pos-100 text-sm" />
          </div>
          {isLoading ?
            <button className="w-full h-[40px] bg-hoory-200 text-white text-sm mt-10 rounded-md flex items-center justify-center"><BtnLoader /></button>
            :
            <button className="w-full h-[40px] bg-hoory-200 text-white text-sm mt-10 rounded-md">Submit</button>
          }
        </form>
        <div className="md:w-6/12 w-full md:pl-4 pl-0 md:mt-0 mt-10">
          <div className="w-full bg-hoory-175 rounded-md p-3 flex flex-col text-justify">
            <span className="text-sm">
              Each AI needs a Name and a Description and the AI Name must be unique for each one of them if you are wondering how to create your AI let me show you an example.
            </span>
            <span className="text-sm mt-4">AI Name</span>
            <span className="text-sm">
              WWOI (WonderFul Wizard Of information)
            </span>
            <span className="text-sm mt-4">Description</span>
            <span className="text-sm ">
              Hello, my name is WWOI and I'm here to help you with your needs and provide the information you need to Know About AlmubdieonTech Company.
            </span>

          </div>
        </div>
      </div>
    </div >
  )
}