import { BtnLoader, CModal } from "components"
import { useAddTag, useEditTodo } from "hooks"
import { useRef } from "react"
import { ModalTagTypes } from "types"
import { methodHandelTag } from "utils"

export const ModalTags = ({ setVisible, visible, selectItem, setSelectItem , check_id }: ModalTagTypes) => {
    const formRef: any = useRef()

    const { isLoading, mutate } = useAddTag(selectItem?.id,check_id, setVisible)

    const handelAdd = (e: any) => {
        e.preventDefault()
        methodHandelTag(formRef, mutate)
    }

    return (
        <>
            <CModal onScap={() => { setSelectItem(null) }} visible={visible} setVisible={setVisible} uId="detail-tag" title="Tags">
                <div className="w-full flex flex-col p-3">
                    <form onSubmit={handelAdd} ref={formRef} className="w-full md:pr-4 pr-0">
                        <div className="w-full flex flex-col">
                            <span className="text-sm mb-2  ml-2 font-bold ">Tag Name</span>
                            <input name="title" type="text" placeholder="Enter your tag " className="w-full h-[40px] rounded-md border border-hoory-75 bg-hoory-100 px-[10px] transition-all duration-500 focus:outline-none focus:border-pos-100 text-sm" required />
                        </div>
                        {isLoading ?
                            <button type={'button'} className="w-full h-[40px] bg-hoory-200 text-white text-sm mt-5 rounded-md flex items-center justify-center relative"><BtnLoader /></button>
                            :
                            <button type="submit" className="w-full h-[40px] bg-hoory-200 text-white text-sm mt-5 rounded-md">Submit</button>
                        }
                    </form>
                </div>
            </CModal>
        </>
    )
}