
export const BotHelpFull = () => {


    return (
        <>
            <div className="w-full text-justify  border border-hoory-200 rounded-md bg-hoory-175 flex flex-col p-2">
                <span className="text-sm leading-6">
                    In order to make effective use of your custom AI, you will need to provide it with data. To accomplish this, navigate to the Training section of your AI interface. Within this section, you will find options to add data and enhance the AI's understanding and ability to answer questions.
                    To ensure optimal performance, it is recommended to format your data in Markdown or .md format. This formatting style allows the AI to better comprehend the information and generate more accurate responses.
                </span>
                <span className="text-sm leading-6">
                    Let me illustrate this process with an example. you can follow these steps:
                </span>
                <span className="text-sm leading-6">
                    Locate the option to add data or training examples.
                    Choose the appropriate format for your data, such as Markdown or .md.
                </span>
                <span className="text-sm leading-6">
                    Provide the necessary information or examples that you want the AI to learn from.
                </span>
                <span className="text-sm leading-6">
                    It's better to give AI clean and readable Data.
                    Save or submit the data to train your AI model.
                </span>
                <span className="text-sm leading-6">
                    By following these steps, you will effectively enhance your AI's capabilities and enable it to provide more accurate and insightful responses.
                </span>
                <span className="text-sm leading-6">
                    Allow me to demonstrate how to add data to your AI.
                </span>
            </div>



        </>
    )
}