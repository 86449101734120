import { ChangePassApi, LoginApi, RegisterApi, SendMailApi } from "api";
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';


let helper = {
  '/p': false,
  '/p/bots': false,
  '/p/teams': false,
  '/p/reports': false,
  '/p/notification': false,
}

export const useLogin = (
  setUserInfo: (obj: any) => void,
) => {
  const navigate = useNavigate()
  return useMutation({
    mutationFn: LoginApi,
    onSuccess: res => {
      if (res.data.status) {
        setUserInfo(res.data.data.user)
        localStorage.setItem('user', JSON.stringify(res.data.data.user))
        localStorage.setItem('token', res.data.data.token)
        localStorage.setItem('helper', JSON.stringify(helper))
        toast(res.data.message, { type: "success" })
        navigate('/p')
      }
      else {
        toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}



export const useRegister = (
  setUserInfo: (obj: any) => void,
) => {
  const navigate = useNavigate()
  return useMutation({
    mutationFn: RegisterApi,
    onSuccess: res => {
      if (res.data.status) {
        setUserInfo(res.data.data.user)
        localStorage.setItem('user', JSON.stringify(res.data.data.user))
        localStorage.setItem('token', res.data.data.token)
        localStorage.setItem('helper', JSON.stringify(helper))
        toast(res.data.message, { type: "success" })
        navigate('/p')
      }
      else {
        toast(res.data.message, { type: "error" })
      }
    },
    onError: err => {
      console.log(err);
    }
  })
}



export const useSendMail = (
  setTypeAuthPage: (str: string) => void,
) => {
  return useMutation({
    mutationFn: SendMailApi,
    onSuccess: res => {
      setTypeAuthPage('change_pass')
      toast(res.data.message, { type: "success" })
    },
    onError: err => {
      console.log(err);
    }
  })
}


export const useChangePass = (
  setTypeAuthPage: (str: string) => void,
) => {
  return useMutation({
    mutationFn: ChangePassApi,
    onSuccess: res => {
      setTypeAuthPage('login')
      toast(res.data.message, { type: "success" })
    },
    onError: err => {
      console.log(err);
    }
  })
}

