import { ConvertionChatCont, ConvertionChatList } from "components"
import { useAllConvertion } from "hooks"
import { useState } from "react"


export const AllConversation = () => {
  const [activeChat, setActiveChat] = useState<any>(null)
  const [botOff, setBotOff] = useState<boolean>(false)
  const { data: listConvertion, isLoading } = useAllConvertion()

  return (
    <div className="w-full md:h-full h-fit  flex flex-col ">
      <div className="w-full md:h-full h-fit  mb-4 flex items-center flex-wrap">
        <div className="md:w-[30%] w-full md:h-full h-fit  md:pr-[10px] mb-4 md:mb-0 pr-0 flex items-center justify-center">
          <ConvertionChatList
            listConvertion={listConvertion?.data?.data}
            setActiveChat={setActiveChat}
            activeChat={activeChat}
            setBotOff={setBotOff}
          />
        </div>
        <div className="md:w-[70%] w-full md:h-full h-fit md:pl-[10px] pl-0 flex items-center justify-center">
          <ConvertionChatCont
            activeChat={activeChat}
            setBotOff={setBotOff}
            botOff={botOff} />
        </div>
      </div>
    </div>
  )
}