import { BtnLoader } from "components/widget"
import {CModal} from "components"
import { useAddCheckList, useAddNotification } from "hooks"
import { useRef } from "react"
import { ModalAddNotificationTypes } from "types"
import { methodHandelCheckList } from "utils"

export const ModalAddCheckList = ({ setVisible, visible }: ModalAddNotificationTypes) => {
    const formRef: any = useRef()

    const { isLoading, mutate } = useAddCheckList( setVisible)

    const handelAdd = (e: any) => {
        e.preventDefault()
        methodHandelCheckList(formRef, mutate)
    }

    return (
        <>
            <CModal  visible={visible} setVisible={setVisible} uId="add-check" title="Add Check List">
                <div className="w-full flex flex-col p-3">
                    <form onSubmit={handelAdd} ref={formRef} className="w-full md:pr-4 pr-0">
                        <div className="w-full flex flex-col">
                            <span className="text-sm mb-2  ml-2 font-bold ">Check List Title</span>
                            <input name="title" type="text" placeholder="Enter title" className="w-full h-[40px] rounded-md border border-hoory-75 bg-hoory-100 px-[10px] transition-all duration-500 focus:outline-none focus:border-pos-100 text-sm" required />
                        </div>
                        <div className="w-full flex flex-col mt-6">
                            <span className="text-sm mb-2  ml-2 font-bold ">Deadline</span>
                            <input name="deadline" type="date" placeholder="Enter deadline" className="w-full h-[40px] rounded-md border border-hoory-75 bg-hoory-100 px-[10px] transition-all duration-500 focus:outline-none focus:border-pos-100 text-sm" required />
                        </div>
                        <div className="w-full flex flex-col mt-6">
                            <span className="text-sm mb-2  ml-2 font-bold ">Check List Description</span>
                            <textarea name="description"  placeholder="Enter description" className="w-full min-h-[100px] rounded-md border border-hoory-75 bg-hoory-100 p-[10px] transition-all duration-500 focus:outline-none focus:border-pos-100 text-sm" required />
                        </div>
                        {isLoading ?
                            <button type={'button'} className="w-full h-[40px] bg-hoory-200 text-white text-sm mt-5 rounded-md flex items-center justify-center relative"><BtnLoader /></button>
                            :
                            <button type="submit" className="w-full h-[40px] bg-hoory-200 text-white text-sm mt-5 rounded-md">Submit</button>
                        }
                    </form>
                </div>
            </CModal>
        </>
    )
}