import { toast } from "react-toastify"

export const methodHandelAddNotification = (
    refForm: any,
    recieverList:any,
    mutate: (obj: any) => void,
) => {
    if(recieverList.length == 0) return toast("Select reciever notification", { type: "error" })
    let list = []
    for(let i of recieverList){
        list.push(i.value)
    }
    let postData = {
        'title': refForm.current['title'].value,
        'description': refForm.current['description'].value,
        'recievers':list
    }
    mutate(postData)
}




export const methodHandelEditNotification = (
    formRef: any,
    mutate: (obj: any) => void,
) => {
    let postData = {
        'title': formRef.current['title'].value,
        'description': formRef.current['description'].value,
    }
    mutate(postData)
}