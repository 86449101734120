import { ContainerLayoutTypes } from "types"
import { handelOpneOrCloseProfileDrower, handelOpneOrCloseSubNav } from "../../utils"
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { CSvgAgents, CSvgApps, CSvgList } from "icon"
import { NavData } from "utils";
import { AllConversation, Bots, Settings, AddBots, Reports, Teams, Notification, Train, CheckList, Todo } from "pages";
import { ProtectedRoute } from "components";
import { Inbox } from "pages/Notification/Inbox";
import { LayoutStore } from "store";

export const Container = ({ children }: ContainerLayoutTypes) => {
    const location = useLocation()
    const {userInfo}= LayoutStore()

    return (
        <div id='baseContainer' className='md:w-[calc(100%-70px)] w-full min-h-screen bg-hoory-125 flex flex-col  transition-all'>
            <div className='w-full h-[60px] bg-hoory-100 border-l border-hoory-75 flex items-center justify-between px-[20px]'>
                <div className="flex items-center">
                    <button type="button" onClick={handelOpneOrCloseSubNav} className="w-[30px] h-[30px] rounded-md   transition-all flex items-center justify-center">
                        <CSvgApps className="w-[18px] " />
                    </button>
                    <div className="ml-2">
                        {NavData.map((item: any, index: number) =>
                            <>
                                {item.allow_bg_path.indexOf(location.pathname) != -1 &&
                                    <div key={index} className="flex items-center">
                                        {item.icon}
                                        <span className="ml-2 ">{item.label}</span>
                                    </div>
                                }
                            </>
                        )}
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="px-2 text-sm h-[25px] bg-hoory-175 rounded text-hoory-200 flex items-center justify-center mr-2">
                        {userInfo?.name} {userInfo?.family}
                    </div>
                    <button onClick={handelOpneOrCloseProfileDrower} className={`w-[40px] h-[40px] cursor-pointer text-lg text-hoory-200 rounded-full bg-hoory-175 flex items-center justify-center `} >
                        <CSvgAgents className="fill-hoory-200 w-4" />
                    </button>
                </div>
            </div>
            <div className='w-full h-full p-[20px] flex items-center justify-center'>
                <div className='w-full h-full  overflow-hidden '>

                    {children}
                    <Routes>
                        <Route path="/" element={<ProtectedRoute><AllConversation /></ProtectedRoute>} />
                        <Route path="/mention" element={<Navigate to="/p" />} />
                        <Route path="/unattended" element={<Navigate to="/p" />} />

                        <Route path="/teams" element={<ProtectedRoute><Teams /></ProtectedRoute>} />

                        <Route path="/notification" element={<ProtectedRoute><Notification /></ProtectedRoute>} />
                        <Route path="/inbox" element={<ProtectedRoute><Inbox /></ProtectedRoute>} />

                        <Route path="/checklist" element={<ProtectedRoute><CheckList /></ProtectedRoute>} />
                        <Route path="/checklist/todo" element={<ProtectedRoute><Todo /></ProtectedRoute>} />

                        <Route path="/bots/add" element={<ProtectedRoute><AddBots /></ProtectedRoute>} />
                        <Route path="/bots/train" element={<ProtectedRoute><Train /></ProtectedRoute>} />
                        <Route path="/bots" element={<ProtectedRoute><Bots /></ProtectedRoute>} />

                        <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
                        <Route path="/settings/inboxes" element={<Navigate to="/p/settings" />} />
                        <Route path="/settings/labels" element={<Navigate to="/p/settings" />} />
                        <Route path="/settings/custom_ttribute" element={<Navigate to="/p/settings" />} />
                        <Route path="/settings/automatic" element={<Navigate to="/p/settings" />} />
                        <Route path="/settings/canned_response" element={<Navigate to="/p/settings" />} />
                        <Route path="/settings/integrations" element={<Navigate to="/p/settings" />} />
                        <Route path="/settings/applications" element={<Navigate to="/p/settings" />} />

                        <Route path="/reports" element={<ProtectedRoute><Reports /></ProtectedRoute>} />
                        <Route path="/reports/conversation" element={<Navigate to="/p/reports" />} />
                        <Route path="/reports/cast" element={<Navigate to="/p/reports" />} />
                        <Route path="/reports/agents" element={<Navigate to="/p/reports" />} />
                        <Route path="/reports/labels" element={<Navigate to="/p/reports" />} />
                        <Route path="/reports/inbox" element={<Navigate to="/p/reports" />} />
                        <Route path="/reports/team" element={<Navigate to="/p/reports" />} />



                    </Routes>
                </div>

            </div>
        </div >
    )
}




