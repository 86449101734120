import { ConfigStoreTypes } from "types"


export const handelOpneOrCloseSubNav = () => {
    let nav = document.getElementById('nav')
    let subNav = document.getElementById('subNav')
    let bgSubNav = document.getElementById('bgSubNav')
    let container = document.getElementById('baseContainer')
    //@ts-ignore
    if ([...subNav.classList].includes('left-[-200px]')) {
        subNav?.classList.remove('left-[-200px]')
        subNav?.classList.add('left-[70px]')
        if (window.innerWidth > 676) {
            container?.classList.remove('md:w-[calc(100%-70px)]')
            container?.classList.add('md:w-[calc(100%-270px)]')
        }
        else {
            nav?.classList.remove('left-[-70px]')
            nav?.classList.add('left-[0]')
            bgSubNav?.classList.remove('right-full')
            bgSubNav?.classList.add('right-[0]')
        }
    }
    else {
        subNav?.classList.add('left-[-200px]')
        subNav?.classList.remove('left-[70px]')
        if (window.innerWidth > 676) {
            container?.classList.add('md:w-[calc(100%-70px)]')
            container?.classList.remove('md:w-[calc(100%-270px)]')
        }
        else {
            nav?.classList.add('left-[-70px]')
            nav?.classList.remove('left-[0]')
            bgSubNav?.classList.add('right-full')
            bgSubNav?.classList.remove('right-[0]')
        }
    }

}



export const handelOnlyOpenSubNav = () => {
    let nav = document.getElementById('nav')
    let subNav = document.getElementById('subNav')
    let bgSubNav = document.getElementById('bgSubNav')
    let container = document.getElementById('baseContainer')
    //@ts-ignore
    if ([...subNav.classList].includes('left-[-200px]')) {
        subNav?.classList.remove('left-[-200px]')
        subNav?.classList.add('left-[70px]')
        if (window.innerWidth > 676) {
            container?.classList.remove('md:w-[calc(100%-70px)]')
            container?.classList.add('md:w-[calc(100%-270px)]')
        }
        else {
            nav?.classList.remove('left-[-70px]')
            nav?.classList.add('left-[0]')
            bgSubNav?.classList.remove('right-full')
            bgSubNav?.classList.add('right-[0]')
        }
    }
}






export const handelOpneOrCloseProfileDrower = () => {
    let profileDrowerBg = document.getElementById('profileDrowerBg')
    let profileDrower = document.getElementById('profileDrower')
    //@ts-ignore
    if ([...profileDrowerBg.classList].includes('invisible')) {
        profileDrower?.classList.remove('invisible')
        profileDrower?.classList.remove('opacity-0')
        profileDrower?.classList.remove('z-[-1]')
        profileDrower?.classList.remove('top-0')
        profileDrower?.classList.remove('right-0')
        profileDrower?.classList.add('right-5')
        profileDrower?.classList.add('top-5')
        profileDrower?.classList.add('visible')
        profileDrower?.classList.add('opacity-1')
        profileDrower?.classList.add('z-[7]')

        profileDrowerBg?.classList.remove('invisible')
        profileDrowerBg?.classList.remove('opacity-0')
        profileDrowerBg?.classList.remove('z-[-1]')
        profileDrowerBg?.classList.add('visible')
        profileDrowerBg?.classList.add('opacity-1')
        profileDrowerBg?.classList.add('z-[6]')


    }
    else {
        profileDrower?.classList.add('top-0')
        profileDrower?.classList.add('right-0')
        profileDrower?.classList.add('invisible')
        profileDrower?.classList.add('opacity-0')
        profileDrower?.classList.add('z-[-1]')
        profileDrower?.classList.remove('right-5')
        profileDrower?.classList.remove('top-5')
        profileDrower?.classList.remove('visible')
        profileDrower?.classList.remove('opacity-1')
        profileDrower?.classList.remove('z-[7]')

        profileDrowerBg?.classList.add('invisible')
        profileDrowerBg?.classList.add('opacity-0')
        profileDrowerBg?.classList.add('z-[-1]')
        profileDrowerBg?.classList.remove('visible')
        profileDrowerBg?.classList.remove('opacity-1')
        profileDrowerBg?.classList.remove('z-[6]')

    }

}




export const handelChnageMode = (mode: string) => {
    const btnLight = document.getElementById('btnLight')
    const btnDark = document.getElementById('btnDark')
    let meta = document.querySelector('meta[name=theme-color]')!
    if (mode == 'light') {
        // localStorage.setItem('mode', '0')
        //@ts-ignore
        meta.content = "#fff"
        document.documentElement.style.setProperty('--hoory-25', '#252525')
        document.documentElement.style.setProperty('--hoory-50', '#37546D')
        document.documentElement.style.setProperty('--hoory-75', '#e6e7ea')
        document.documentElement.style.setProperty('--hoory-100', '#fff')
        document.documentElement.style.setProperty('--hoory-125', '#f4f6fd')
        document.documentElement.style.setProperty('--hoory-175', '#f4f6fd')
        document.documentElement.style.setProperty('--hoory-200', '#6362e7')
        // btnLight?.classList.remove('bg-white')
        // btnLight?.classList.remove('bg-hoory-100')
        // btnLight?.classList.remove('text-hoory-50')
        // btnLight?.classList.add('bg-hoory-200')
        // btnLight?.classList.add('text-white')

        // btnDark?.classList.add('bg-white')
        // btnDark?.classList.add('text-hoory-50')
        // btnDark?.classList.remove('bg-hoory-200')
        // btnDark?.classList.remove('text-white')
    }
    else {
        // localStorage.setItem('mode', '1')
        //@ts-ignore
        meta.content = "#262932"
        document.documentElement.style.setProperty('--hoory-25', '#9cb5ba')
        document.documentElement.style.setProperty('--hoory-50', '#9cb5ba')
        document.documentElement.style.setProperty('--hoory-75', '#2d3644')
        document.documentElement.style.setProperty('--hoory-100', '#262932')
        document.documentElement.style.setProperty('--hoory-125', '#1d1e26')
        document.documentElement.style.setProperty('--hoory-175', '#1d1e26')
        document.documentElement.style.setProperty('--hoory-200', '#6362e7')
        // btnLight?.classList.add('bg-hoory-100')
        // btnLight?.classList.add('text-hoory-50')
        // btnLight?.classList.remove('bg-hoory-200')
        // btnLight?.classList.remove('text-white')

        // btnDark?.classList.remove('bg-white')
        // btnDark?.classList.remove('bg-hoory-100')
        // btnDark?.classList.remove('text-hoory-50')
        // btnDark?.classList.add('bg-hoory-200')
        // btnDark?.classList.add('text-white')
    }
}




export const methodHandelChangeConfig = (
    config:ConfigStoreTypes,
    keyType: 'status' | 'mode' | 'color',
    valueType: string ,
    mutate:(obj:any)=>void ,
) => {
    let postData:any = JSON.parse(JSON.stringify(config))
    postData[keyType] = valueType
    if(keyType == 'mode'){
        postData['isMode'] = 1 ;
    }
    mutate(postData)
}