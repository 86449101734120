import { baseApi, base_url } from "./baseApi";

export const StartChatApi = async (postData: any) => {
    const data = await baseApi({ url: `${base_url}chat/message-start`, method: "POST" , body:JSON.stringify(postData) })
    return { data };
}


export const GetChatApi = async (postData: any) => {
    const data = await baseApi({ url: `${base_url}chat/message-list`, method: "POST" , body:JSON.stringify(postData) })
    return { data };
}


export const SendMessageChatApi = async (postData: any) => {
    const data = await baseApi({ url: `${base_url}chat/message-add`, method: "POST" , body:JSON.stringify(postData) })
    return { data };
}



export const GetChatListApi = async () => {
    const data = await baseApi({ url: `${base_url}chat/chat-list`, method: "GET" , token:true })
    return { data };
}


export const SetOffApi = async (postData: any) => {
    const data = await baseApi({ url: `${base_url}chat/set-off`, method: "POST" , body:JSON.stringify(postData) })
    return { data };
}