import { AddNewTrain, ModalDeleteTrains, ModalEditTrain, SkeletonTrainsList, CModal, TrainHelp } from "components"
import { useAllTrains } from "hooks"
import { CSvgTrash, CSvgSearch, CSvgEdit } from "icon"
import { useState } from "react"
import { useSearchParams } from "react-router-dom"

export const Train = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { data: trains, isLoading  } = useAllTrains(searchParams.get("id"))
  const [selectItem, setSelectItem] = useState<any>(null)
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false)
  const [visibleEdit, setVisibleEdit] = useState<boolean>(false)


  const handelOpen = async (bot: any, type: string) => {
    setSelectItem(bot)
    switch (type) {
      case "edit":
        setVisibleEdit(true)
        break;
      case "delete":
        setVisibleDelete(true)
        break;
    }
  }

  return (
    <>
      <div className='w-full flex flex-col justify-center items-center  bg-hoory-100  rounded-lg  '>
        <div className="w-full flex flex-wrap p-4">
          <div className="w-full mb-4">
            <TrainHelp />
          </div>
          <div className="w-full   mb-5 flex items-center justify-between">
            <form className="w-fit flex items-center border border-hoory-75 rounded overflow-hidden">
              <input className="w-[150px] h-[35px] text-xs bg-transparent px-1 border-0 focus:outline-none" placeholder="Search"></input>
              <button className="w-[35px] h-[35px] flexi items-center justify-center bg-transparent">
                <CSvgSearch className="w-[30px] stroke-gray-400" />
              </button>
            </form>
            <button className="px-3 h-[35px] text-xs rounded-md  bg-hoory-175 text-hoory-200"> New +</button>
          </div>
          <div className="md:w-6/12 w-full md:pr-4 pr-0 md:order-1 order-2">
            <div className='w-full flex flex-col '>
              {isLoading == false && trains?.data.data?.length != 0 && trains?.data?.data.map((item: any, index: number) =>
                <div key={index} className="w-full p-[12px] cursor-pointer flex justify-between items-center bg-hoory-175 shadow rounded-md mb-4">
                  <div className="w-[calc(100%-60px)] flex flex-col">
                    <h3 className="text-sm">{item?.name}</h3>
                  </div>
                  <div className=" flex items-center justify-between">
                    <button onClick={() => handelOpen(item, 'edit')} title="Edit" className="w-[30px] h-[30px] bg-hoory-100 shadow mx-1  flex items-center justify-center rounded">
                      <CSvgEdit className="fill-hoory-50 w-[18px]" />
                    </button>
                    <button onClick={() => handelOpen(item, 'delete')} title="Delete" className="w-[30px] h-[30px] bg-hoory-100 shadow mx-1  flex items-center justify-center rounded">
                      <CSvgTrash className="fill-hoory-50 w-[18px]" />
                    </button>

                  </div>
                </div>
              )}
            </div >
            {isLoading == false && trains?.data.data?.length == 0 &&
              <div className="w-full h-[180px] border border-hoory-75 rounded-md p-[12px] text-gray-400 flex flex-col justify-center items-center text-center">
                <span>Trains not found</span>
              </div>
            }
            {isLoading && <SkeletonTrainsList />}
          </div>
          <div className="md:w-6/12 w-full h-fit  bg-hoory-175 rounded-md p-3  mb-5 md:order-2 order-1">
            <AddNewTrain
              bot_id={searchParams.get("id")}
            />
          </div>
        </div>
      </div >

      <ModalEditTrain
        visible={visibleEdit}
        setVisible={setVisibleEdit}
        setSelectItem={setSelectItem}
        selectItem={selectItem}
        bot_id={searchParams.get("id")}
      />

      <ModalDeleteTrains
        visible={visibleDelete}
        setVisible={setVisibleDelete}
        setSelectItem={setSelectItem}
        selectItem={selectItem}
        bot_id={searchParams.get("id")}
      />
    </>
  )
}