import { baseApi, base_url } from "./baseApi";

export const TeamsApi = async () => {
    const data = await baseApi({ url: `${base_url}team`, method: "GET" , token:true })
    return { data };
}


export const DeleteTeamApi = async (email:any) => {
    const data = await baseApi({ url: `${base_url}team/${email}/delete`, method: "DELETE" , token:true })
    return { data };
}


export const AddTeamApi = async (email:string) => {
    const data = await baseApi({ url: `${base_url}team/${email}/add`, method: "POST" , token:true  })
    return { data };
}


export const ActiveTeamApi = async (postData:any) => {
    const data = await baseApi({ url: `${base_url}team/${postData?.email}/active-update`, method: "PUT" , token:true , body:JSON.stringify(postData) })
    return { data };
}


