import { useEffect } from "react"
import { ReportsGrathTypes } from "types"


export const OverViewTraffic = ({grath}:ReportsGrathTypes) => {


    useEffect(()=>{
        console.log(grath);
        
    },[grath])


    return (
        <div className="w-full  bg-hoory-100 rounded-lg py-[10px] md:px-[20px] px-[10px]">
            <div className="w-full flex items-center justify-between">
                <h3>Conversation Traffic</h3>
                <button className="text-xs w-[120px] h-[40px] rounded-md bg-hoory-175 text-hoory-200">Download Report</button>
            </div>
            <div className="w-full overflow-x-auto mt-5">
                <div className="w-full flex flex-col px-4">
                    {/* {[0, 0, 0, 0, 0, 0, 0].map((item: any, index: number) => */}
                    {grath && Object.entries(grath).map((item:any,index:any) => 
                        <div key={index} className="w-full flex items-center justify-between mb-1 h-[40px]  ">
                            <div className="w-[80px] text-xs ">{item[1]?.length}</div>
                            <div className="w-[calc(100%-80px)] h-[40px] flex items-center">
                                {item[1].map((itemDay: any, indexDay: number) =>
                                    <div key={indexDay} className={`w-full min-w-[30px] h-[30px]  rounded-md mx-1 ${itemDay > 0 ? 'bg-hoory-200' : 'bg-hoory-125'}`}></div>
                                )}
                            </div>
                        </div>
                    )}
                    <div className="w-full flex items-center justify-between mb-1 h-[40px]  ">
                        <div className="w-[80px] text-xs "></div>
                        <div className="w-[calc(100%-80px)] h-[40px] flex items-center">
                            {new Array(30).fill("").map((item: any, index: number) =>
                                <div className="w-full min-w-[30px] h-[30px] flex items-center justify-center text-[9px] mx-1">{index} - {index + 1}</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}