import { CSvgChart } from "icon"
import { ReportsChartTypes } from "types"


export const OverViewChart = ({client_count , message_count}:ReportsChartTypes) => {




    return (
        <div className="w-full mb-4 flex items-center flex-wrap">
            <div className="md:w-6/12 w-full  md:pr-[10px] mb-4 md:mb-0 pr-0 flex items-center justify-center">
                <div className="w-full  bg-hoory-100 rounded-lg flex flex-col md:p-[20px] p-[10px]">
                    <h3>Open Conversations</h3>
                    <div className="w-full flex items-center mt-[20px]">
                        <div className="w-full  flex flex-col items-center justify-center">
                            <CSvgChart className="md:w-[45px] w-[30px] fill-hoory-200" />
                            <span className="my-1 md:text-base text-sm">{client_count?.total ? client_count?.total : 0}</span>
                            <span className="text-gray-400 text-xs">Total</span>
                        </div>
                        <div className="w-full  flex flex-col items-center justify-center">
                            <CSvgChart className="md:w-[45px] w-[30px] fill-[gold]" />
                            <span className="my-1 md:text-base text-sm">{client_count?.['last_month'] ? client_count?.['last_month'] : 0}</span>
                            <span className="text-gray-400 text-xs">last Month</span>
                        </div>
                        <div className="w-full  flex flex-col items-center justify-center">
                            <CSvgChart className="md:w-[45px] w-[30px] fill-[tomato]" />
                            <span className="my-1 md:text-base text-sm">{client_count?.['last_week'] ? client_count?.['last_week'] : 0}</span>
                            <span className="text-gray-400 text-xs">Last Week</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="md:w-6/12 w-full md:pl-[10px] pl-0 flex items-center justify-center">
                <div className="w-full  bg-hoory-100 rounded-lg flex flex-col md:p-[20px] p-[10px]">
                    <h3>Agent Conversations Status </h3>
                    <div className="w-full flex items-center mt-[20px]">
                        <div className="w-full  flex flex-col items-center justify-center">
                            <CSvgChart className="md:w-[45px] w-[30px] fill-hoory-200" />
                            <span className="my-1 md:text-base text-sm">{message_count?.total ? message_count?.total : 0}</span>
                            <span className="text-gray-400 text-xs">Total </span>
                        </div>
                        <div className="w-full  flex flex-col items-center justify-center">
                            <CSvgChart className="md:w-[45px] w-[30px] fill-[gold]" />
                            <span className="my-1 md:text-base text-sm">{message_count?.['last_month'] ? message_count?.['last_month'] : 0}</span>
                            <span className="text-gray-400 text-xs">Last Month</span>
                        </div>
                        <div className="w-full  flex flex-col items-center justify-center">
                            <CSvgChart className="md:w-[45px] w-[30px] fill-[tomato]" />
                            <span className="my-1 md:text-base text-sm">{message_count?.['last_week'] ? message_count?.['last_week'] : 0}</span>
                            <span className="text-gray-400 text-xs">Last Week </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}