import {  CSvgBell, CSvgMiniLogo } from "icon"
import { handelOnlyOpenSubNav } from "../../utils"
import { useLocation, useNavigate } from "react-router-dom"
import { NavData } from "utils";
import BotImg from 'media/logo/logo.png'


export const Nav = () => {
  const location = useLocation();
  const navigate = useNavigate()

  const handelChangeRoute = async (path: string) => {
    if (location.pathname != path) {      
      await handelOnlyOpenSubNav()
      navigate(path)
    }
  }

  return (
    <div id='nav' className='w-[70px] py-[15px] top-0 bottom-0 bg-hoory-100 border-r border-hoory-75 fixed z-[5] md:left-0 left-[-70px] transition-all duration-300 flex flex-col items-center justify-between'>
      <div className="w-full flex flex-col items-center">
        <CSvgMiniLogo className="w-[40px] " />
        {/* <img src={BotImg} className="w-[40px] rounded-[7px]" /> */}
        <div className="w-full flex flex-col items-center mt-10">
          {NavData.map((item: any, index: number) =>
            <div onClick={() => handelChangeRoute(item.path)} key={index} className={`w-[40px] h-[40px] mb-3 cursor-pointer rounded-2xl flex items-center justify-center ${item.allow_bg_path.indexOf(location.pathname) != -1 && 'bg-hoory-175'} `} >
              {item.icon}
            </div>
          )}
        </div>
      </div>
      <div className="w-full flex flex-col items-center">
        <div className={`w-[40px] h-[40px] mb-3 cursor-pointer rounded-2xl flex items-center justify-center `} >
          <CSvgBell className="w-[23px] fill-hoory-50" />
        </div>
      </div>
    </div>
  )
}



