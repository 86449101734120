import { BtnLoader } from "components/widget"
import {CModal} from "components"
import { useEditBot, useEditNotification } from "hooks"
import { useEffect, useRef } from "react"
import { ModalEditNotificationTypes } from "types"
import { methodHandelEditBots, methodHandelEditNotification } from "utils"

export const ModalEditNotification = ({ setVisible, visible, selectItem, setSelectItem }: ModalEditNotificationTypes) => {
    const formRef: any = useRef()

    const { isLoading, mutate } = useEditNotification(selectItem?.id, setVisible)

    const handelEdit = (e: any) => {
        e.preventDefault()
        methodHandelEditNotification(formRef, mutate)
    }

    useEffect(() => {
        if (selectItem != null) {
            formRef.current['title'].value = selectItem?.title
            formRef.current['description'].value = selectItem.description
        }
    }, [selectItem])

    return (
        <>
            <CModal onScap={() => { setSelectItem(null) }} visible={visible} setVisible={setVisible} uId="edit-bots" title="Edit Bots">
                <div className="w-full flex flex-col p-3">
                    <form onSubmit={handelEdit} ref={formRef} className="w-full md:pr-4 pr-0">
                        <div className="w-full flex flex-col">
                            <span className="text-sm mb-2  ml-2 font-bold ">Notification Title</span>
                            <input name="title" type="text" placeholder="Enter your title " className="w-full h-[40px] rounded-md border border-hoory-75 bg-hoory-100 px-[10px] transition-all duration-500 focus:outline-none focus:border-pos-100 text-sm" required />
                        </div>
                        <div className="w-full flex flex-col mt-6">
                            <span className="text-sm mb-2  ml-2 font-bold ">Notification Description</span>
                            <textarea name="description" placeholder="Enter your Description " className="w-full h-[100px] rounded-md border border-hoory-75 bg-hoory-100 p-[10px] transition-all duration-500 focus:outline-none focus:border-pos-100 text-sm" />
                        </div>
                        {isLoading ?
                            <button type={'button'} className="w-full h-[40px] bg-hoory-200 text-white text-sm mt-5 rounded-md flex items-center justify-center relative"><BtnLoader /></button>
                            :
                            <button type="submit" className="w-full h-[40px] bg-hoory-200 text-white text-sm mt-5 rounded-md">Submit Edit</button>
                        }
                    </form>
                </div>
            </CModal>
        </>
    )
}