import { baseApi, base_url } from "./baseApi";

export const BotsApi = async () => {
    const data = await baseApi({ url: `${base_url}bots/list`, method: "GET" , token:true })
    return { data };
}


export const AddBotsApi = async (postData: any) => {
    const data = await baseApi({ url: `${base_url}bots/create`, method: "POST" , body:JSON.stringify(postData) , token:true })
    return { data };
}


export const EditBotApi = async (postData: any , bot_id:number) => {
    const data = await baseApi({ url: `${base_url}bots/${bot_id}`, method: "PUT" , body:JSON.stringify(postData) , token:true })
    return { data };
}


export const DeleteBotApi = async (bot_id:number) => {
    const data = await baseApi({ url: `${base_url}bots/${bot_id}`, method: "DELETE" , token:true })
    return { data };
}


export const TrainsApi = async (bot_id:any) => {    
    const data = await baseApi({ url: `${base_url}bots/${bot_id}/trains`, method: "GET" , token:true })
    return { data };
}


export const AddTrainsApi = async (postData: any , bot_id:any) => {
    const data = await baseApi({ url: `${base_url}bots/${bot_id}/trains`, method: "POST" , body:JSON.stringify(postData) , token:true })
    return { data };
}


export const EditTrainsApi = async (postData: any , bot_id:number , trains_id:number) => {
    const data = await baseApi({ url: `${base_url}bots/${bot_id}/trains/${trains_id}`, method: "PUT" , body:JSON.stringify(postData) , token:true })
    return { data };
}


export const DeleteTrainsApi = async (bot_id:number, trains_id:number) => {
    const data = await baseApi({ url: `${base_url}bots/${bot_id}/trains/${trains_id}`, method: "DELETE" , token:true })
    return { data };
}

