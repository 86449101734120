import { CSvgAgents, CSvgApps, CSvgAttch, CSvgLogo, CSvgMiniLogo, CSvgStop, CSvgTrash, CSvgUser } from "icon"
import { useEffect, useRef, useState } from "react"
import ConvertionVector from 'media/chat/convertion.avif'
import { useConvertionMessage, useManualMessage, useSendMessage } from "hooks"
import { ChatStore, ConvertionStore } from "store"
import { closeWebSocketConnection, createWebSocketConnection, methodHandelListMessage, methodHandelSendMessage, methodHandelSendMessageSocket, methodHandelSetOff } from "utils"
import { BtnLoader } from "components/widget"

export const ConvertionChatCont = ({ activeChat, setBotOff, botOff }: any) => {
  const formRef: any = useRef()
  const [socket, setSocket] = useState<any>(null);
  const { convertion, setConvertion, convertionPagination, setConvertionPagination } = ConvertionStore()
  const { mutate: mutateManual, isLoading: loadingManual } = useManualMessage(setBotOff)
  const { isLoading, mutate } = useConvertionMessage(
    setConvertionPagination,
    setConvertion,
    convertion,
  )
  const { isLoading: loadingAdd, mutate: mutateAdd } = useSendMessage(convertion, setConvertion, formRef, 'operator')


  const loadMoreMessage = () => {
    let page = convertion.length == 0 ? 1 : convertionPagination?.current + 1
    methodHandelListMessage(activeChat?.chat_room_id, page, mutate)
  }

  const handelSetBotOff = () => {
    methodHandelSetOff(activeChat, mutateManual)
  }

  const handelSendMessage = (e: any) => {
    e.preventDefault()
    methodHandelSendMessage(activeChat, mutateAdd, formRef, 'operator')
  }

  const handelSendMessageSocket = (e: any) => {
    e.preventDefault()
    methodHandelSendMessageSocket(socket, formRef, convertion, setConvertion, 'operator')
  }

  useEffect(() => {
    createWebSocketConnection(convertion, setConvertion, setSocket, 'operator', activeChat)
  }, [setConvertion, activeChat])

  useEffect(() => {
    closeWebSocketConnection(socket, setSocket)
  }, [socket]);

  useEffect(() => {
    if (activeChat != null) {
      loadMoreMessage()
    }
  }, [activeChat])

  return (
    <div className="w-full md:h-full h-[400px] bg-hoory-100 rounded-lg flex flex-col justify-between ">
      {activeChat == null &&
        <div className="w-full h-full flex items-center justify-center flex-col ">
          <img src={ConvertionVector} className="w-[300px] max-w-[90%] rounded-full" />
          <span className="text-gray-500 mt-4">Select convertion to start chat .</span>
        </div>
      }
      {activeChat != null &&
        <>
          <div className="w-full py-2 flex items-center justify-between border-b border-hoory-75 md:px-[20px] px-[10px]">
            <div className="flex items-center">
              <div className="md:w-[50px] w-[40px] md:h-[50px] h-[40px] bg-hoory-175 flex items-center justify-center text-hoory-200 rounded-full shadow">
                <CSvgAgents className="fill-hoory-200 w-[25px]" />
              </div>
              <div className="ml-2 flex flex-col items-start">
                <span className="text-sm">{activeChat?.chat_room_client_id}</span>
                <span className="text-gray-400 text-xs mt-1">{activeChat?.chat_room_pixely_bot_name}</span>
                <span className="text-gray-400 text-xs mt-1">{activeChat?.bot_id}</span>
              </div>
            </div>
            {/* <div className="flex items-center">
              <button className="w-[30px] h-[30px] mx-1 rounded-md bg-hoory-175 flex items-center justify-center"><CSvgApps className="w-[15px]" /></button>
              <button className="w-[30px] h-[30px] mx-1 rounded-md bg-hoory-175 flex items-center justify-center"><CSvgTrash className="fill-hoory-200 h-[15px]" /></button>
              <button className="px-2 h-[30px] rounded-md  text-hoory-200 md:text-sm text-[12px] bg-hoory-175">Block</button>
            </div> */}
          </div>
          <div id='convertion-panel-container' className="w-full h-full md:max-h-screen max-h-fit pb-[30px] pt-[15px] overflow-y-auto px-[20px]">
            <div className='w-full flex justify-center'>
              {convertionPagination?.total > convertionPagination?.current &&
                <>
                  {isLoading ?
                    <button className=' h-[30px] w-[100px] mx-auto bg-hoory-200 text-xs text-hoory-175 rounded-full flex items-center justify-center relative '><BtnLoader /></button>
                    :
                    <button onClick={loadMoreMessage} className=' h-[30px] w-[100px] mx-auto bg-hoory-200 text-xs text-hoory-175 rounded-full '>Load more...</button>
                  }
                </>
              }
            </div>
            {Array.isArray(convertion) && convertion?.map((item: any, index: number) =>
              <div key={index} className={`w-full my-5  flex  ${item?.type == 'client' ? 'justify-end' : 'justify-start'} `}>
                <div className="md:w-[50%] w-[90%] relative flex flex-col">
                  <div className={`w-[30px] h-[30px] p-1 absolute rounded-full overflow-hidden border border-hoory-75 shadow top-[-25px] bg-hoory-100 flex items-center justify-center ${item?.type == 'client' ? 'right-[20px] ' : 'left-[20px]'}`}>
                    {item?.type == 'client' ?
                      <CSvgUser className="w-[23px] stroke-hoory-200" />
                      :
                      <CSvgLogo className="w-[20px] fill-hoory-200" />
                    }
                  </div>
                  <div className="w-full p-[15px] bg-hoory-125 rounded-lg flex flex-col mt-[-10px]">
                    <div className={`w-full flex ${item?.type == 'client' ? 'justify-start' : 'justify-end'}`}>
                      <span className="text-[12px] text-gray-600">{item?.date?.slice(0, 10)}</span>
                    </div>
                    <span className="mt-2 text-sm leading-6">{item?.message} </span>
                  </div>
                </div>
              </div>
            )}
          </div>

          <form ref={formRef} id="send-message-convertion-form" onSubmit={handelSendMessageSocket} className="w-full py-4  md:px-[20px] px-[10px] border-t border-hoory-75 flex items-center justify-between ">
            <input name='message' placeholder="Type message" className="md:w-[calc(100%-150px)] w-[calc(100%-120px)] h-[35px] px-2 text-sm focus:outline-none border border-hoory-75 rounded-md bg-transparent" />

            {loadingAdd ?
              <button type="button" className="md:w-[100px] w-[70px] h-[35px] flex items-center justify-center text-sm bg-hoory-200 text-hoory-175 rounded-md"><BtnLoader /></button>
              :
              <button type="submit" className="md:w-[100px] w-[70px] h-[35px] flex items-center justify-center text-sm bg-hoory-200 text-hoory-175 rounded-md">Send</button>
            }
            <button type="button" className="w-[35px] h-[35px] rounded-md bg-hoory-175 flex items-center justify-center">
              <CSvgStop className="w-[25px] fill-hoory-200" />
            </button>
          </form>

          {/* <div className="w-full py-4  md:px-[20px] px-[10px] border-t border-hoory-75 flex items-center justify-between">
              {loadingManual ?
                <button className="w-full h-[35px] text-sm bg-hoory-200 text-hoory-175 rounded-md flex items-center justify-center relative cursor-not-allowed">
                  <BtnLoader />
                </button>
                :
                <button onClick={handelSetBotOff} className="w-full h-[35px] text-sm bg-hoory-200 text-hoory-175 rounded-md flex items-center justify-center relative">
                  Send a manual message
                </button>
              }
            </div> */}
        </>
      }
    </div>
  )
}