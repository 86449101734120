import create from 'zustand';

interface IChat {
    convertion: any,
    setConvertion: (data: any) => void,
    convertionPagination: {
        'total': number,
        'current': number,
    },
    setConvertionPagination: (data: any) => void,
}

// zustand state

export const ConvertionStore = create<IChat>((set) => ({
    // لیست چت های ادمین را نگه میدارد
    convertion: [],
    setConvertion: (newChat) => set((state) => ({
        convertion: typeof newChat === 'function' ? newChat(state.convertion) : newChat
    })),
    // صفحه بندی چت ادمین
    convertionPagination: {
        'total': 1,
        'current': 1,
    },
    setConvertionPagination: (data: any) => set(() => ({ convertionPagination: data })),
}))
