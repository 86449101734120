
export const methodHandelCheckList = (
    refForm: any,
    mutate: (obj: any) => void,
) => {
    let postData = {
        'title': refForm.current['title'].value,
        'description': refForm.current['description'].value,
        'deadline': refForm.current['deadline'].value,
        'completed':false
    }
    mutate(postData)
}



export const methodHandelTag= (
    refForm: any,
    mutate: (obj: any) => void,
) => {
    let postData = {
        'name': refForm.current['title'].value,
    }
    mutate(postData)
}


