import { useAllBots } from "hooks"
import { CSvgTrash, CSvgBook, CSvgEdit, CSvgTag, CSvgSearch } from "icon"
import { Link, useNavigate } from 'react-router-dom'
import { ModalDisplayScriptBots, SkeletonBotsList, ModalEditBots, ModalDeleteBots, BotHelpEmpty, BotHelpFull } from "components"
import { useState } from "react"

export const Bots = () => {
  const navigation = useNavigate()
  const { data: bots, isLoading } = useAllBots()
  const [selectItem, setSelectItem] = useState<any>(null)
  const [visibleScript, setVisibleScript] = useState<boolean>(false)
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false)
  const [visibleEdit, setVisibleEdit] = useState<boolean>(false)

  const handelOpen = async (bot: any, type: string) => {
    setSelectItem(bot)
    switch (type) {
      case "script":
        setVisibleScript(true)
        break;
      case "edit":
        setVisibleEdit(true)
        break;
      case "delete":
        setVisibleDelete(true)
        break;
    }
  }

  return (
    <>
      <div className='w-full flex flex-col justify-center items-center pb-[20px] bg-hoory-100  rounded-lg  '>
        <div className="w-full   flex items-center justify-between p-4">
          <form className="w-fit flex items-center border border-hoory-75 rounded overflow-hidden">
            <input className="w-[150px] h-[35px] text-xs bg-transparent px-1 border-0 focus:outline-none" placeholder="Search"></input>
            <button className="w-[35px] h-[35px] flexi items-center justify-center bg-transparent">
              <CSvgSearch className="w-[30px] stroke-gray-400" />
            </button>
          </form>
          <button onClick={() => { navigation('/p/bots/add') }} className="px-3 h-[35px] text-xs rounded-md  bg-hoory-175 text-hoory-200"> New +</button>
        </div>
        <div className="w-full flex flex-wrap  px-4 ">
          <div className="md:w-6/12 w-full md:pr-4 pr-0">
            <div className='w-full flex flex-col '>
              {isLoading == false && bots?.data?.data?.length != 0 && bots?.data.data?.map((item: any, index: number) =>
                <div key={index} className="w-full p-[12px] flex justify-between items-center bg-hoory-175  shadow rounded-md mb-4">
                  <div className="w-[calc(100%-100px)] flex flex-col pr-2">
                    <h3 className="text-sm">{item?.name}</h3>
                    <span className="text-xs text-gray-500 mt-2 text-justify">{item?.description}</span>
                  </div>
                  <div className="w-[100px] flex flex-col ">
                    <Link to={`/p/bots/train?id=${item?.id}`}>
                      <button title="Train" className="w-full bg-hoory-100 h-[30px] shadow mb-2  flex items-center justify-center rounded">
                        <div className="w-[25px] h-[25px] flex items-center justify-center mr-2">
                          <CSvgBook className="fill-hoory-50 w-[18px] " />
                        </div>
                        <span className="text-xs ">Train</span>
                      </button>
                    </Link>
                    <button onClick={() => handelOpen(item, 'script')} title="Script" className="w-full bg-hoory-100 h-[30px] shadow mb-2  flex items-center justify-center rounded">
                      <div className="w-[25px] h-[25px] flex items-center justify-center mr-2">
                        <CSvgTag className="fill-hoory-50 w-[18px] " />
                      </div>
                      <span className="text-xs">Script</span>
                    </button>
                    <button onClick={() => handelOpen(item, 'edit')} title="Edit" className="w-full bg-hoory-100 h-[30px] shadow mb-2  flex items-center justify-center rounded">
                      <div className="w-[25px] h-[25px] flex items-center justify-center mr-2">
                        <CSvgEdit className="fill-hoory-50 w-[18px] mr-2" />
                      </div>
                      <span className="text-xs">Edit</span>
                    </button>
                    <button onClick={() => handelOpen(item, 'delete')} title="Delete" className="w-full bg-hoory-100 h-[30px] shadow mb-2  flex items-center justify-center rounded">
                      <div className="w-[25px] h-[25px] flex items-center justify-center mr-2">
                        <CSvgTrash className="fill-hoory-50 w-[18px] " />
                      </div>
                      <span className="text-xs ">Delete</span>
                    </button>

                  </div>
                </div>
              )}
              {isLoading && <SkeletonBotsList />}
            </div >
          </div>
          <div className="md:w-6/12 w-full md:pl-4 pl-0 md:mt-0 mt-5">
            {isLoading == false && bots?.data?.data?.length != 0 &&
              <BotHelpFull />
            }
          </div>
        </div>

        {isLoading == false && bots?.data?.data?.length == 0 &&
          <div className="w-full flex flex-col  px-4 ">
            <BotHelpEmpty />
            <div className="w-full h-[100px] rounded-md p-[12px] text-gray-400 flex flex-col justify-center items-center text-center">
              <Link to='/p/bots/add'>
                <button className="w-[150px] h-[40px] bg-hoory-175 text-hoory-200  text-sm mt-4 rounded-md">New AI</button>
              </Link>
            </div>

          </div>
        }

      </div >



      <ModalDisplayScriptBots
        visible={visibleScript}
        setVisible={setVisibleScript}
        setSelectItem={setSelectItem}
        selectItem={selectItem}
      />

      <ModalEditBots
        visible={visibleEdit}
        setVisible={setVisibleEdit}
        setSelectItem={setSelectItem}
        selectItem={selectItem}
      />

      <ModalDeleteBots
        visible={visibleDelete}
        setVisible={setVisibleDelete}
        setSelectItem={setSelectItem}
        selectItem={selectItem}
      />

    </>
  )
}