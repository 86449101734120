import { BtnLoader } from "components";
import { useLogin, useSendMail } from "hooks";
import { methodHandelLogin, methodHandelSendMail } from "../../utils";
import { useRef, useState } from "react";
import { CSvgEye, CSvgEyeOff } from "icon";

export const SendMailComponents = ({ setTypeAuthPage }: any) => {
    const refForm: any = useRef()
    const { isLoading, mutate } = useSendMail(setTypeAuthPage)

    const handelSendMail = (e: any) => {
        e.preventDefault();
        methodHandelSendMail(refForm, mutate)
    }

    return (
        <>
            <form ref={refForm} onSubmit={handelSendMail} className={`w-full flex flex-col pt-[30px]  `}>
                <div className="w-full min-h-[40px] bg-hoory-125 mb-10 flex items-center px-2 border-l-2 border-hoory-200 text-sm">
                    Enter your email address to recover your password
                </div>
                <div className="w-full flex flex-col">
                    <span className="text-xs mb-2 text-gray-500 ml-2 ">Email Address</span>
                    <input name='email' type="email" placeholder="Enter your email " className="w-full h-[40px] rounded-full border border-hoory-75  bg-transparent px-[10px] focus:outline-none text-sm" required />
                </div>
                {isLoading ?
                    <button type="button" className="w-full h-[40px] bg-hoory-200 text-white text-sm rounded-full mt-10 flex items-center justify-center relative"><BtnLoader /></button>
                    :
                    <button type="submit" className="w-full h-[40px] bg-hoory-200 text-white text-sm rounded-full mt-10">Submit</button>
                }
                <div className="w-full flex flex-col mt-10">
                    <div className="w-full relative border-t border-hoory-75  flex justify-center ">
                        <span className="bg-hoory-100 -mt-[14px] flex w-[40px] justify-center">or</span>
                    </div>
                    <div className="w-full flex items-center justify-between mt-4 text-xs px-2">
                        {/* <span className="cursor-pointer">Do you have an account ?</span> */}
                        <span onClick={() => { setTypeAuthPage('login') }} className="text-blue-500 cursor-pointer">login page</span>
                    </div>
                </div>
            </form>
        </>
    )
}