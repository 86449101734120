import {  ModalViewInbox } from "components"
import Table from "components/widget/CTable/Table"
import { useInbox } from "hooks"
import {  CSvgMore } from "icon"
import { useState } from "react"

export const Inbox = () => {
  const [visibleEdit, setVisibleEdit] = useState<boolean>(false)
  const [selectItem, setSelectItem] = useState<any>(null)
  const { data: inbox, isLoading } = useInbox()



  const col = [
    {
      title: "Type",
      render: (i: any) => (<>{i?.type == 'inbox_confirmation' ? 'Confirm' : i?.title}</>)
    },
    {
      title: "Description",
      render: (i: any) => (<>{} {i?.type == 'inbox_confirmation' ? i?.message?.slice(0, 30) : i?.description?.slice(0, 30)} ...</>)
    },
    {
      title: "Date",
      render: (i: any) => (<>{i?.timestamp?.slice(0, 10)}</>)
    },
    {
      title: "More",
      render: (i: any) => (
        <>
          <button onClick={() => handelViewMore(i)} className="w-[35px] h-[35px] rounded border border-hoory-200 text-hoory-200 flex items-center justify-center">
            <CSvgMore className="w-[20px] fill-hoory-200" />
          </button>
        </>
      )
    },
  ]


  const handelViewMore = (item: any) => {
    setSelectItem(item)
    setVisibleEdit(true)
  }

  return (
    <>
      <div className="w-full flex flex-col md:p-[20px] p-[10px] bg-hoory-100  rounded-lg">
        <div className="w-full   flex items-center justify-end mb-4">
        </div>
        <Table columns={col} data={inbox?.data?.data} />
      </div>

      <ModalViewInbox
        visible={visibleEdit}
        setVisible={setVisibleEdit}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
      />

    </>
  )
}