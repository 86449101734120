import { SkeletonBase } from "./SkeletonBase"

export const SkeletonTrainsList = () => {

    return (
        <>
            {[0, 0, 0,0,0].map((item: any, index: number) =>
                <div key={index} className="w-full h-[55px] overflow-hidden cursor-pointer flex justify-between relative items-center bg-hoory-175 shadow rounded-md mb-4">
                    <SkeletonBase />
                </div>
            )}
        </>
    )
}